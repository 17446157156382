import React from 'react';
import Breadcrumb from '../../components/navigation/breadcrumb';

export interface Props {
    title: string;
    text: string;
}

const ContentPage = (props: Props) => {
    return <>
        <div className="content d-flex flex-column flex-column-fluid">
            <Breadcrumb title={props.title} text={props.text} ></Breadcrumb> 
            <div className="d-flex flex-column-fluid"> 
                <div className="container">
                    <div className="row"></div>
                </div>
            </div>
        </div>
    </>
}

export default ContentPage;